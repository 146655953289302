// Route components

// eslint-disable-next-line no-unused-vars
import { injectIntl } from 'react-intl' // Needs to be imported for it to be picked up by the tooling
// intl is actually being passed from the Components that use the routes

import { lazy } from 'react'
import TestSnackbar from 'ui/StyledComponents/TestSnackbar'
import Info from './ui/InternalInfo'
import ManagerSignatureRequests from 'ui/ManagerSignatureRequests'
import ManagerMessageService from 'ui/ManagerMessageService'
const CompanySite = lazy(() => import('./ui/CompanySite'))
const CompanyBulletinboard = lazy(() => import('./ui/CompanyBulletinboard'))
const CompanyBulletins = lazy(() => import('./ui/CompanyBulletins'))
const CompanyMessages = lazy(() => import('./ui/CompanyMessages'))
const CompanyDocuments = lazy(() => import('./ui/CompanyDocuments'))
const CompanyDocumentsPageCompany = lazy(() =>
	import('./ui/CompanyDocuments/v1/DocumentsPageCompany'),
)
const CompanyDocumentsPageApartment = lazy(() =>
	import('./ui/CompanyDocuments/v1/DocumentsPageApartment'),
)
const CompanyDocumentsPageBoard = lazy(() =>
	import('./ui/CompanyDocuments/v1/DocumentsPageBoard'),
)
const CompanyDocumentsPageMeetingArchives = lazy(() =>
	import('./ui/CompanyDocuments/v1/DocumentsPageMeetingArchives'),
)
const CompanyDocumentsV2All = lazy(() =>
	import('./ui/CompanyDocuments/v2/DocumentsAll'),
)
const CompanyDocumentsV2Latest = lazy(() =>
	import('./ui/CompanyDocuments/v2/DocumentsLatest'),
)
const CompanyDocumentsV2Hub = lazy(() => import('./ui/CompanyDocuments/v2'))
const CompanySurveys = lazy(() => import('./ui/CompanySurveys'))
const CompanyFormPublic = lazy(() => import('./ui/CompanyFormPublic'))
const CompanyFormResponses = lazy(() => import('./ui/CompanyFormResponses'))
const CompanyFormResponseSingle = lazy(() =>
	import('./ui/CompanyFormResponseSingle'),
)
const CompanyFormResponseUnauthenticated = lazy(() =>
	import('ui/CompanyFormResponseUnauthenticated'),
)
const InternalFormResponseRedirector = lazy(() =>
	import('ui/InternalFormResponseRedirector'),
)
const InternalFormResponseAuthenticationDone = lazy(() =>
	import('ui/InternalFormResponseAuthenticationDone'),
)
const CompanyForms = lazy(() => import('./ui/CompanyForms'))
const CompanyFormList = lazy(() => import('./ui/CompanyFormList'))
const CompanyFinancials = lazy(() => import('./ui/CompanyFinancials'))
const CompanyApartmentFinancials = lazy(() =>
	import('./ui/CompanyFinancials/CompanyApartmentFinancials'),
)
const CompanyTaskManager = lazy(() => import('./ui/TaskManager/Company'))
const CompanyMaintenanceBook = lazy(() => import('./ui/CompanyMaintenanceBook'))
const CompanyTurnlistList = lazy(() => import('./ui/CompanyTurnlistList'))
const CompanyTurnlist = lazy(() => import('./ui/CompanyTurnlist'))
const CompanyQueues = lazy(() => import('./ui/CompanyQueues'))
const CompanyMeetings = lazy(() => import('./ui/CompanyMeetings'))
const CompanyMeetingDocuments = lazy(() =>
	import('./ui/CompanyMeetingDocuments'),
)
const CompanyUsers = lazy(() => import('./ui/CompanyUsers'))
const CompanyApartments = lazy(() => import('./ui/CompanyApartments'))
const CompanyBuildings = lazy(() => import('./ui/CompanyBuildings'))
const CompanyParkingSpaces = lazy(() =>
	import('./ui/CompanyParkingSpaces/CompanyParkingSpaces'),
)
const CompanyParkingSpacesIntro = lazy(() =>
	import('./ui/CompanyParkingSpacesIntro'),
)
const CompanyParkingSpacesReserve = lazy(() =>
	import('./ui/CompanyParkingSpacesReserve/CompanyParkingSpacesReserve'),
)
const CompanyParkingSpacesQueue = lazy(() =>
	import('./ui/CompanyParkingSpacesQueue/CompanyParkingSpacesQueue'),
)
const CompanyParkingSpacesTerminate = lazy(() =>
	import('./ui/CompanyParkingSpacesTerminate/CompanyParkingSpacesTerminate'),
)
const CompanyParkingSpaceRegistry = lazy(() =>
	import('./ui/CompanyParkingSpaceRegistry/CompanyParkingSpaceRegistry'),
)

const CompanyStorageUnits = lazy(() =>
	import('./ui/CompanyStorageUnits/CompanyStorageUnits'),
)
const CompanyStorageUnitsIntro = lazy(() =>
	import('./ui/CompanyStorageUnitsIntro'),
)
const CompanyStorageUnitsReserve = lazy(() =>
	import('./ui/CompanyStorageUnitsReserve/CompanyStorageUnitsReserve'),
)
const CompanyStorageUnitsQueue = lazy(() =>
	import('./ui/CompanyStorageUnitsQueue/CompanyStorageUnitsQueue'),
)
const CompanyStorageUnitsTerminate = lazy(() =>
	import('./ui/CompanyStorageUnitsTerminate/CompanyStorageUnitsTerminate'),
)
const CompanyStorageUnitRegistry = lazy(() =>
	import('./ui/CompanyStorageUnitRegistry/CompanyStorageUnitRegistry'),
)
const CompanySettings = lazy(() => import('./ui/CompanySettings'))
const CompanyReportsCompanyFeature = lazy(() =>
	import('./ui/CompanySettings/Reports/CompanyFeature'),
)
const CompanyInfoscreens = lazy(() => import('./ui/CompanyInfoscreens'))
const CompanySmartlocks = lazy(() => import('./ui/CompanySmartlocks'))
const CompanySmartlockEntries = lazy(() =>
	import('./ui/CompanySmartlocks/SmartlockEntries'),
)
const CompanySmartlockIncidents = lazy(() =>
	import('./ui/CompanySmartlocks/SmartlockIncidents'),
)
const CompanyInvite = lazy(() => import('./ui/CompanyInvite'))
const CompanyBoardFeatures = lazy(() => import('./ui/CompanyBoardFeatures'))

const CompanyProducts = lazy(() => import('./ui/CompanyProducts'))
const CompanyPayments = lazy(() => import('./ui/CompanyPayments'))

const CompanyBanlist = lazy(() => import('./ui/CompanyBanlist'))

const InternalMyCompany = lazy(() => import('./ui/InternalMyCompany'))
const InternalLanding = lazy(() => import('./ui/InternalLanding'))
const InternalFindCompany = lazy(() => import('./ui/InternalFindCompany'))
const InternalUserSettings = lazy(() => import('./ui/InternalUserSettings'))
const InternalUserNotificationCenter = lazy(() =>
	import('./ui/InternalUserNotificationCenter'),
)
const InternalMeeting = lazy(() => import('./ui/InternalMeeting'))
const InternalMeetingRedirector = lazy(() =>
	import('./ui/InternalMeetingRedirector'),
)
const InternalMeetingAuxRedirector = lazy(() =>
	import('./ui/InternalMeetingAuxRedirector'),
)
const InternalMeetingAuxRedirector_redesign = lazy(() =>
	import(
		'./ui/InternalMeeting/NativeApp/InternalMeetingAuxRedirector_redesign'
	),
)
const InternalInformationRequestRespond = lazy(() =>
	import('./ui/InternalInformationRequestRespond'),
)
const InternalInformationRequestReport = lazy(() =>
	import('./ui/InternalInformationRequestReport'),
)
const InternalAudit = lazy(() => import('./ui/InternalAudit'))
const InternalSign = lazy(() => import('./ui/InternalSign'))
const InternalSignEmailLanding = lazy(() =>
	import('./ui/InternalSign/EmailLanding'),
)
const InternalSignatureRequests = lazy(() =>
	import('./ui/InternalSignatureRequests'),
)
const InternalSignatureRequestsUser = lazy(() =>
	import('./ui/InternalSignatureRequestsUser'),
)
const InternalNotFound = lazy(() => import('./ui/InternalNotFound'))
const InternalDesktopLogin = lazy(() => import('./ui/InternalDesktopLogin'))
const InternalDesktopLoginCallback = lazy(() =>
	import('./ui/InternalDesktopLoginCallback'),
)
const InternalLoginRedirector = lazy(() =>
	import('./ui/InternalLoginRedirector'),
)
const InternalPayment = lazy(() => import('./ui/InternalPayment'))
const InternalPaymentReceipt = lazy(() => import('./ui/InternalPaymentReceipt'))

const InternalInvoice = lazy(() => import('./ui/InternalInvoice'))

const ManagerOverview = lazy(() => import('./ui/ManagerOverview'))
const ManagerCompanies = lazy(() => import('./ui/ManagerCompanies'))
const ManagerCompaniesRoles = lazy(() => import('./ui/ManagerCompaniesRoles'))
const ManagerBulletins = lazy(() => import('./ui/ManagerBulletins'))
const ManagerSurveys = lazy(() => import('./ui/ManagerSurveys'))
const ManagerForms = lazy(() => import('./ui/ManagerForms'))
const ManagerEmployees = lazy(() => import('./ui/ManagerEmployees'))
const ManagerSupportConversations = lazy(() =>
	import('./ui/ManagerSupportConversations'),
)
const ManagerInvite = lazy(() => import('./ui/ManagerInvite'))
const ManagerCompanyInviteRequests = lazy(() =>
	import('./ui/ManagerCompanyInviteRequests'),
)
const ManagerFinance = lazy(() => import('./ui/ManagerFinance'))
const PropertyInformation = lazy(() => import('./ui/PropertyInformation'))
const ManagerSettings = lazy(() => import('./ui/ManagerSettings'))
const ManagerReports = lazy(() => import('./ui/ManagerReports'))
const ManagerReportsCompanyFeature = lazy(() =>
	import('./ui/ManagerReports/CompanyFeature'),
)
const ManagerReportsCompanyMeetings = lazy(() =>
	import('./ui/ManagerReports/CompanyMeetings'),
)
const ManagerVotingKiosk = lazy(() => import('./ui/ManagerVotingKiosk'))
const ManagerVotingKioskCompanies = lazy(() =>
	import('./ui/ManagerVotingKiosk/CompaniesList'),
)
const ManagerVotingKioskMeetings = lazy(() =>
	import('./ui/ManagerVotingKiosk/MeetingsList'),
)

const MessageService = lazy(() => import('./ui/MessageService'))
const MessageServiceUnauthenticatedView = lazy(() =>
	import('./ui/MessageServiceUnauthenticatedView'),
)
const DigitalDeliveryConsents = lazy(() =>
	import('./ui/ManagerReports/DigitalDeliveryConsents'),
)
const ManagerCompaniesReport = lazy(() =>
	import('./ui/ManagerReports/Companies'),
)
const ManagerReportsCompanyReservations = lazy(() =>
	import('./ui/ManagerReports/CompanyReservations'),
)
const ManagerReportsCompanyReservationsBilling = lazy(() =>
	import('./ui/ManagerReports/CompanyReservationsBilling'),
)
const ManagerReportsCompanyInvoiceRows = lazy(() =>
	import('./ui/ManagerReports/CompanyInvoiceRows'),
)
const ManagerReportsCompanyDocuments = lazy(() =>
	import('./ui/ManagerReports/CompanyDocuments'),
)
const ManagerReportsSales = lazy(() =>
	import('./ui/ManagerReports/ManagerSales'),
)
const ManagerReportsKohoPostal = lazy(() =>
	import('./ui/ManagerReports/ManagerKohoPostal'),
)
const ManagerProducts = lazy(() => import('./ui/ManagerProducts'))
const ManagerPayments = lazy(() => import('./ui/ManagerPayments'))
const ManagerQuotes = lazy(() => import('./ui/ManagerQuotes'))
const ManagerContracts = lazy(() => import('./ui/ManagerContracts'))
const ManagerSelection = lazy(() => import('./ui/ManagerSelection'))
const ManagerMeetings = lazy(() => import('./ui/ManagerMeetings'))
const ManagerMeetingsShareholder = lazy(() =>
	import('./ui/ManagerMeetings/Shareholder'),
)
const ManagerMeetingsBoard = lazy(() => import('./ui/ManagerMeetings/Board'))
const ManagerMeetingsInfo = lazy(() => import('./ui/ManagerMeetings/Info'))
const ManagerMeetingsCustom = lazy(() => import('./ui/ManagerMeetings/Custom'))
const ManagerTaskManager = lazy(() => import('./ui/TaskManager/Manager'))
const ManagerMaintenanceBook = lazy(() => import('./ui/MaintenanceBook'))
const ManagerCompanyImport = lazy(() => import('./ui/ManagerCompanyImport'))
const ManagerCompanyUserImport = lazy(() =>
	import('./ui/ManagerCompanyUserImport'),
)
const ManagerPartners = lazy(() => import('./ui/ManagerPartners'))
const ManagerPropertyManagersCertificate = lazy(() =>
	import('./ui/ManagerPropertyManagersCertificate'),
)

const ConfidentialUsers = lazy(() => import('./ui/ConfidentialUsers'))
const ConfidentialCompanies = lazy(() => import('./ui/ConfidentialCompanies'))
const ConfidentialPostalBatches = lazy(() =>
	import('./ui/ConfidentialPostalBatches'),
)
const ConfidentialMassUpdate = lazy(() => import('ui/ConfidentialMassUpdate'))
const ConfidentialStats = lazy(() => import('./ui/ConfidentialStats'))
const ConfidentialInvoicingSaaS = lazy(() =>
	import('./ui/ConfidentialInvoicingSaaS'),
)
const ConfidentialInvoicingSigning = lazy(() =>
	import('./ui/ConfidentialInvoicingSigning'),
)
const ConfidentialInvoicingPostal = lazy(() =>
	import('./ui/ConfidentialInvoicingPostal'),
)
const ConfidentialInvoicingSMS = lazy(() =>
	import('./ui/ConfidentialInvoicingSMS'),
)
const ConfidentialCompaniesMap = lazy(() =>
	import('./ui/ConfidentialCompaniesMap'),
)

const ConfidentialInfoscreens = lazy(() =>
	import('./ui/ConfidentialInfoscreens'),
)

const ConfidentialMeetings = lazy(() => import('./ui/ConfidentialMeetings'))

const ConfidentialFivaldiCustomers = lazy(() =>
	import('./ui/ConfidentialFivaldiCustomers'),
)

const ConfidentialOiva360 = lazy(() => import('./ui/ConfidentialOiva360'))

const ConfidentialCustomerbase = lazy(() =>
	import('./ui/ConfidentialCustomerbase'),
)

const ConfidentialPayments = lazy(() => import('./ui/ConfidentialPayments'))
const ConfidentialContractProposals = lazy(() =>
	import('./ui/ConfidentialContractProposals'),
)

const CompanyInfoscreen = lazy(() => import('./ui/CompanyInfoscreen'))
const CompanyInfoscreenMosaic = lazy(() =>
	import('./ui/CompanyInfoscreen/CompanyInfoscreenMosaic'),
)
const StrongAuthShim = lazy(() => import('./ui/StrongAuthShim'))
const CompanyDocumentSign = lazy(() => import('./ui/CompanyDocumentSign'))
const CompanySignatureRequest = lazy(() =>
	import('./ui/CompanySignatureRequest'),
)
const InternalDocumentCheck = lazy(() => import('./ui/InternalDocumentCheck'))
const InternalDocumentVerify = lazy(() => import('./ui/InternalDocumentVerify'))
const ManagerContractSign = lazy(() => import('./ui/ManagerContractSign'))
const ManagerCompaniesMap = lazy(() => import('./ui/ManagerCompaniesMap'))
const ButtonsForTesting = lazy(() =>
	import('./ui/StyledComponents/ButtonsForTesting'),
)
const CompanyAdministrativeInformation = lazy(() =>
	import('./ui/CompanyAdministrativeInformation'),
)

const meetingTitleFunc = (intl) =>
	intl.formatMessage({
		defaultMessage: 'Kokous',
		description: 'Title of an app section for users: Meeting.',
	})

const routes = [
	// Manager
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Yleiskatsaus',
				description:
					'Title of an app section for property managers: Overview page.',
			}),
		component: ManagerOverview,
		path: '/manager',
		icon: 'table_chart',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager',
		disabled: false,
		public: true,
		exact: true,
		feature: 'site',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kohteet',
				description:
					'Title of an app section for property managers: Listing of properties.',
			}),
		component: ManagerCompanies,
		path: '/manager/companies',
		icon: 'home',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-customer',
		disabled: false,
		public: false,
		feature: 'companies',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kohteet kartalla',
				description:
					'Title of an app section for managers: companies on a map.',
			}),
		component: ManagerCompaniesMap,
		path: '/manager/companies_map',
		icon: 'map',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager-hidden',
		disabled: false,
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kohteiden sidokset',
				description:
					'Title of an app section for property managers: Listing of property roles.',
			}),
		component: ManagerCompaniesRoles,
		path: '/manager/companies_roles',
		icon: 'home',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
		public: false,
		feature: 'companies',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Äänestyskioski',
				description:
					'Title of an app section for property managers: Voting kiosk.',
			}),
		component: ManagerVotingKiosk,
		path: '/manager/voting_kiosk',
		icon: 'home',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
		public: false,
		feature: 'voting',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Valitse kohde',
				description:
					'Title of an app section for property managers: Voting kiosk company selection.',
			}),
		component: ManagerVotingKioskCompanies,
		path: '/manager/voting_kiosk_companies',
		icon: 'home',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
		public: false,
		feature: 'voting',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Valitse kokous',
				description:
					'Title of an app section for property managers: Voting kiosk meeting selection.',
			}),
		component: ManagerVotingKioskMeetings,
		path: '/:company/voting_kiosk_meetings',
		relativePath: '/voting_kiosk_meetings',
		icon: 'videocam',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'hidden',
		disabled: false,
		public: false,
		feature: 'voting',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Ilmoitustaulu',
				description:
					'Title of an app section for property managers: Aggregation of housing company bulletinboards.',
			}),
		component: ManagerBulletins,
		path: '/manager/bulletins',
		icon: 'dashboard',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-customer',
		disabled: false,
		public: true,
		feature: 'bulletins',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asukaskyselyt',
				description:
					'Title of an app section for property managers: Aggregation of housing company surveys.',
			}),
		component: ManagerSurveys,
		path: '/manager/surveys',
		icon: 'timeline',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-customer',
		disabled: false,
		public: true,
		feature: 'surveys',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asiakaspalveluviestit',
				description:
					'Title of an app section for property managers: Customer support conversations.',
			}),
		component: ManagerSupportConversations,
		path: '/manager/conversations',
		icon: 'chat_bubble',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-customer',
		disabled: false,
		public: true,
		feature: 'messages',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Ilmoitukset ja tilaukset',
				description:
					'Title of an app section for property managers: Notices and orders.',
			}),
		component: ManagerForms,
		path: '/manager/forms',
		icon: 'description',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-customer',
		disabled: false,
		public: true,
		feature: 'forms',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tehtävienhallinta',
				description:
					'Title of an app section for property managers: Task management.',
			}),
		component: ManagerTaskManager,
		path: '/manager/tasks',
		icon: 'check_circle',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager',
		public: true,
		experimental: true,
		feature: 'tasks',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tehtävienhallinta',
				description:
					'Title of an app section for property managers: Task management.',
			}),
		component: ManagerTaskManager,
		path: '/internal/task_manager',
		icon: 'check_circle',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'hidden',
		public: true,
		experimental: true,
		feature: 'tasks',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Huoltokirja',
				description:
					'Title of an app section for property managers: Maintenance book.',
			}),
		component: ManagerMaintenanceBook,
		path: '/manager/maintenance_book',
		icon: 'construction',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager',
		feature: 'maintenanceBook',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Isännöitsijäntodistukset',
				description:
					"Title of an app section for property managers: Property Managers's Certificate.",
			}),
		component: ManagerPropertyManagersCertificate,
		path: '/manager/property_managers_certificate',
		icon: 'article',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-customer',
		feature: 'propertyManagersCertificate',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kokoukset',
				description: 'Title of an app section for property managers: Meetings.',
			}),
		component: ManagerMeetings,
		path: '/manager/meetings',
		icon: 'videocam',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-customer',
		disabled: false,
		exact: true,
		feature: 'meetings',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoitukset',
				description:
					'Title of an app app sub section for property managers: Aggregation of signature requests of housing companies.',
			}),
		component: ManagerSignatureRequests,
		path: '/manager/signature_requests',
		icon: 'edit',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-customer',
		disabled: false,
		exact: true,
		feature: 'signing',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Viestintä',
				description: 'Title of an app section for managers: Message Service.',
			}),
		component: ManagerMessageService,
		path: '/manager/message_service_internal',
		icon: 'mail',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager-internal',
		feature: 'messageService',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asiakirjat',
				description:
					'Title of an app section for managers: Internal documents.',
			}),
		component: CompanyDocumentsV2Hub,
		path: '/manager/documents_internal',
		icon: 'folder',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'manager-internal',
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Palaverit',
				description:
					'Title of an app section for property managers: Internal meetings.',
			}),
		component: CompanyMeetings,
		path: '/manager/meetings_internal',
		icon: 'groups',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-internal',
		disabled: false,
		exact: true,
		feature: 'meetingsInternal',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Raportit',
				description: 'Title of an app section for property managers: Reports.',
			}),
		component: ManagerReports,
		path: '/manager/reports',
		icon: 'insert_chart_outlined',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-internal',
		disabled: false,
		exact: true,
		feature: 'reports',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tuotteet',
				description: 'Title of an app section for property managers: Products.',
			}),
		component: ManagerProducts,
		path: '/manager/products',
		icon: 'category',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-internal',
		disabled: false,
		exact: true,
		feature: 'contracts',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksutapahtumat',
				description: 'Title of an app section for property managers: Payments.',
			}),
		component: ManagerPayments,
		path: '/manager/payments',
		icon: 'receipt',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-internal',
		disabled: false,
		exact: true,
		feature: 'payments',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tarjoukset',
				description: 'Title of an app section for property managers: Quotes.',
			}),
		component: ManagerQuotes,
		path: '/manager/quotes',
		icon: 'outgoing_mail',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-internal',
		disabled: false,
		exact: true,
		feature: 'contracts',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Sopimukset',
				description:
					'Title of an app section for property managers: Contracts.',
			}),
		component: ManagerContracts,
		path: '/manager/contracts',
		icon: 'euro',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-internal',
		disabled: false,
		exact: true,
		feature: 'contracts',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Henkilöstö',
				description:
					'Title of an app section for property managers: Listing of employees.',
			}),
		component: ManagerEmployees,
		path: '/manager/employees',
		icon: 'group',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-internal',
		disabled: false,
		public: true,
		feature: 'employees',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kumppanit',
				description:
					'Title of an app section for property managers: Listing of partners.',
			}),
		component: ManagerPartners,
		path: '/manager/partners',
		icon: 'store',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-internal',
		disabled: false,
		public: true,
		feature: 'partners',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Isännöintitoimiston käyttöoikeudet',
				description:
					'Title of an app section for property managers: Accepting an invite to get permissions as an employee.',
			}),
		component: ManagerInvite,
		path: '/internal/manager_invite', // TODO: change this and the related cloud function
		exact: true,
		icon: 'person_add',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'hidden',
		disabled: false,
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Odottavat käyttöoikeuspyynnöt',
				description:
					'Title of an app section for property managers: listing of pending company permission requests.',
			}),
		component: ManagerCompanyInviteRequests,
		path: '/manager/company_invite_requests',
		exact: true,
		icon: 'assignment_ind',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Talous',
				description: 'Title of an app section for property managers: Finance.',
			}),
		component: ManagerFinance,
		path: '/manager/finance',
		relativePath: '/finance',
		icon: 'account_balance',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		public: false,
		feature: 'finance',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asetukset',
				description: 'Title of an app section for property managers: Settings.',
			}),
		component: ManagerSettings,
		path: '/manager/settings',
		icon: 'settings',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-internal',
		public: true,
		feature: 'settings',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Yhtiökokoukset',
				description:
					'Title of an app app sub section for property managers: Aggregation of shareholder meetings of housing companies.',
			}),
		component: ManagerMeetingsShareholder,
		path: '/manager/meetings_shareholder',
		icon: 'videocam',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
		exact: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Hallituksen kokoukset',
				description:
					'Title of an app app sub section for property managers: Aggregation of board meetings of housing companies.',
			}),
		component: ManagerMeetingsBoard,
		path: '/manager/meetings_board',
		icon: 'videocam',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
		exact: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Infotilaisuudet',
				description:
					'Title of an app app sub section for property managers: Aggregation of info meetings of housing companies.',
			}),
		component: ManagerMeetingsInfo,
		path: '/manager/meetings_info',
		icon: 'videocam',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
		exact: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Muut kokoukset',
				description:
					'Title of an app app sub section for property managers: Aggregation of other meetings of housing companies.',
			}),
		component: ManagerMeetingsCustom,
		path: '/manager/meetings_custom',
		icon: 'videocam',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
		exact: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Käyttöperusteiset ominaisuudet',
				description:
					'Title of a report for property managers: Usage based features (billing).',
			}),
		component: ManagerReportsCompanyFeature,
		path: '/manager/reports/company_features',
		icon: 'insert_chart_outlined',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kokoukset',
				description:
					'Title of a report for property managers: company meetings.',
			}),
		component: ManagerReportsCompanyMeetings,
		path: '/manager/reports/company_meetings',
		icon: 'videocam',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Sähköisen osakaspostituksen suostumukset',
				description:
					'Title of a report for property managers: consents of electronic messaging.',
			}),
		component: DigitalDeliveryConsents,
		path: '/manager/reports/digital_delivery_consents',
		icon: 'insert_chart_outlined',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Isännöidyt kohteet',
				description:
					'Title of a report for property managers: managed housing companies.',
			}),
		component: ManagerCompaniesReport,
		path: '/manager/reports/manager_companies',
		icon: 'insert_chart_outlined',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Varaukset varauskalentereista',
				description: 'Title of a report for property managers: Reservations.',
			}),
		component: ManagerReportsCompanyReservations,
		path: '/manager/reports/company_reservations',
		icon: 'insert_chart_outlined',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Varausten laskutusraportti',
				description:
					'Title of a report for property managers: Reservations billing.',
			}),
		component: ManagerReportsCompanyReservationsBilling,
		path: '/manager/reports/company_reservations_billing',
		icon: 'insert_chart_outlined',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Sopimuslaskutusraportti',
				description: 'Title of a report for property managers: Invoice rows.',
			}),
		component: ManagerReportsCompanyInvoiceRows,
		path: '/manager/reports/company_invoice_rows',
		icon: 'insert_chart_outlined',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asiakirjojen määrä',
				description:
					'Title of a report for property managers: Document counts.',
			}),
		component: ManagerReportsCompanyDocuments,
		path: '/manager/reports/company_documents',
		icon: 'insert_chart_outlined',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksupalvelun myyntiraportti',
				description: 'Title of a report for property managers: Sales report.',
			}),
		component: ManagerReportsSales,
		path: '/manager/reports/manager_sales',
		icon: 'insert_chart_outlined',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Postitukset kohoon',
				description: 'Title of a report for property managers: Koho postal.',
			}),
		component: ManagerReportsKohoPostal,
		path: '/manager/reports/koho_postal',
		icon: 'mail',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Valittu isännöintitoimisto',
				description:
					'Title of an app section for property managers: property management company selection.',
			}),
		component: ManagerSelection,
		path: '/manager/selection',
		icon: 'work',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Työpöytäkirjautuminen',
				description:
					'Title of an app section for property managers: desktop app login.',
			}),
		component: InternalDesktopLogin,
		path: '/internal/desktop_login',
		icon: 'lock',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Työpöytäkirjautuminen tehty',
				description:
					'Title of an app section for property managers: desktop app login success page.',
			}),
		component: InternalDesktopLoginCallback,
		path: '/internal/desktop_login_callback',
		icon: 'check',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager-hidden',
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Käyttäjäasetukset',
				description:
					'Title of an app section for property managers: user account settings.',
			}),
		component: InternalUserSettings,
		path: '/manager/usersettings',
		icon: 'settings',
		exact: true,
		category: 'manager-hidden',
		color: '#6B849A',
		colorDark: '#385167',
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Ilmoituskeskus',
				description:
					'Title of an app section for property managers: user notification center.',
			}),
		component: InternalUserNotificationCenter,
		path: '/manager/notifications',
		icon: 'notifications',
		exact: true,
		category: 'manager-hidden',
		color: '#6B849A',
		colorDark: '#385167',
		public: true,
	},

	// Internal routes
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Vahva tunnistautuminen',
				description:
					'Title of an app section for users: Strong authentication flow.',
			}),
		component: StrongAuthShim,
		path: '/internal/id_callback',
		icon: 'lock',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		exact: true,
		category: 'hidden',
		disabled: false,
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Hakemaasi sivua ei löytynyt',
				description:
					'Title of an app section, which is displayed when an incorrect URL was used: 404 page.',
			}),
		component: InternalNotFound,
		path: '/internal/notfound',
		exact: true,
		icon: 'error',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: true,
		public: true,
	},
	{
		title: '',
		component: () => (window.location.href = 'https://vii.lu'),
		path: '/site',
		exact: true,
		category: 'hidden',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Sinun taloyhtiösi',
				description:
					'Title of an app section, which is lists the housing companies the user is part of.',
			}),
		component: InternalMyCompany,
		path: '/internal/mycompany',
		icon: 'home',
		exact: true,
		category: 'hidden',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Sinun taloyhtiösi',
				description:
					'Title of an app section that is displayed if no housing company is selected: Landing page.',
			}),
		component: InternalLanding,
		path: '/',
		icon: 'home',
		exact: true,
		category: 'hidden',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Löydä talosivut',
				description:
					'Title of an app section that allows the user to find the page of their housing company.',
			}),
		component: InternalFindCompany,
		path: '/internal/find',
		icon: 'home',
		exact: true,
		category: 'hidden',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Käyttäjäasetukset',
				description:
					'Title of an app section for users: user account settings.',
			}),
		component: InternalUserSettings,
		path: '/internal/usersettings',
		icon: 'settings',
		exact: true,
		category: 'user',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Ilmoituskeskus',
				description:
					'Title of an app section for users: user notifications center.',
			}),
		component: InternalUserNotificationCenter,
		path: '/internal/notifications',
		icon: 'notifications',
		exact: true,
		category: 'user',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Versiotieto',
				description: 'Title of an app section for users: version info.',
			}),
		component: Info,
		path: '/internal/info',
		icon: 'settings',
		exact: true,
		category: 'user',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Käyttöoikeudet',
				description:
					'Title of an app section for users: accepting an user role in a housing company.',
			}),
		component: CompanyInvite,
		path: '/internal/company_invite',
		exact: true,
		icon: 'home',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lomake',
				description: 'Title of an app section for users: Single form.',
			}),
		component: InternalFormResponseRedirector,
		path: '/internal/form_response_single',
		icon: 'description',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		public: true,
		feature: 'forms',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lomake',
				description: 'Title of an app section for users: Form redirector.',
			}),
		component: InternalFormResponseRedirector,
		path: '/internal/form_response_redirector',
		icon: 'description',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: true,
		feature: 'forms',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Valmis',
				description:
					'Title of an app section for users: Form authentication done.',
			}),
		component: InternalFormResponseAuthenticationDone,
		path: '/internal/form_response_authentication_done',
		icon: 'done',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lomake',
				description:
					'Title of an app section for users: Single form processing view.',
			}),
		component: CompanyFormResponseSingle,
		path: '/internal/form_response_processing',
		icon: 'description',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		public: true,
		feature: 'forms',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lomake',
				description:
					'Title of an app section for users: Form response view without login.',
			}),
		component: CompanyFormResponseUnauthenticated,
		path: '/internal/form_response_unauthenticated',
		icon: 'description',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: true,
		feature: 'forms',
	},
	{
		title: meetingTitleFunc,
		component: InternalMeeting,
		path: '/:company/meeting',
		relativePath: '/meeting',
		icon: 'videocam',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		disabled: false,
		public: true,
		feature: 'meetings',
	},
	{
		title: meetingTitleFunc,
		component: InternalMeeting,
		path: '/manager/meeting_internal',
		icon: 'videocam',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager-hidden',
		disabled: false,
		public: true,
		feature: 'meetings',
	},
	{
		title: meetingTitleFunc,
		component: InternalMeetingRedirector,
		path: '/:company/kokous',
		relativePath: '/kokous',
		icon: 'videocam',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: true,
		feature: 'meetings',
	},
	{
		title: meetingTitleFunc,
		component: InternalMeetingRedirector,
		path: '/kokous',
		icon: 'videocam',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: true,
		feature: 'meetings',
	},
	{
		title: meetingTitleFunc,
		component: InternalMeetingAuxRedirector,
		path: '/internal/meeting_aux_redirector',
		icon: 'videocam',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: true,
		feature: 'meetings',
	},
	{
		title: meetingTitleFunc,
		component: InternalMeetingAuxRedirector_redesign,
		path: '/internal/meeting_aux_redirector_redesign',
		icon: 'videocam',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: true,
		feature: 'meetings',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tarkasta asiakirja',
				description: 'Title of an app section for users: document audit.',
			}),
		component: InternalAudit,
		path: '/internal/audit',
		icon: 'description',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoita asiakirja',
				description: 'Title of an app section for users: document signing.',
			}),
		component: InternalSign,
		path: '/internal/sign',
		icon: 'edit',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoita asiakirja',
				description: 'Title of an app section for users: document signing.',
			}),
		component: InternalSignEmailLanding,
		path: '/internal/sign_email',
		icon: 'edit',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoitukset',
				description: 'Title of an app section for users: signature requests.',
			}),
		component: InternalSignatureRequests,
		path: '/internal/signature_requests',
		icon: 'edit',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoituspyynnöt',
				description: 'Title of an app section for users: signature requests.',
			}),
		component: InternalSignatureRequestsUser,
		path: '/internal/signature_requests_user',
		icon: 'edit',
		category: 'hidden',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tietopyyntö',
				description:
					'Title of an app section for users: information request form.',
			}),
		component: InternalInformationRequestRespond,
		path: '/internal/inforeq',
		icon: 'info',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		disabled: false,
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tietopyyntöraportti',
				description:
					'Title of an app section for users: information request report.',
			}),
		component: InternalInformationRequestReport,
		path: '/internal/inforeq_report',
		icon: 'info',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		disabled: false,
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kirjaudu',
				description: 'Title of an app section for users: sign in.',
			}),
		component: InternalLoginRedirector,
		path: '/internal/login_redirector',
		icon: 'info',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		public: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kokouksen asiakirjat',
				description:
					'Title of an app section for users: meeting documents listing.',
			}),
		component: CompanyMeetingDocuments,
		path: '/:company/meeting_documents',
		relativePath: '/meeting_documents',
		icon: 'description',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		disabled: false,
		public: false,
		feature: 'meetings',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kokouksen asiakirjat',
				description:
					'Title of an app section for users: meeting documents listing.',
			}),
		component: CompanyMeetingDocuments,
		path: '/manager/meeting_documents_internal',
		icon: 'description',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager-hidden',
		disabled: false,
		public: false,
		feature: 'meetings',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksutoimeksianto',
				description: 'Title of an app section for users: payments.',
			}),
		component: InternalPayment,
		path: '/internal/payment',
		icon: 'shopping_cart',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kuitti',
				description: 'Title of an app section for users: receipt.',
			}),
		component: InternalPaymentReceipt,
		path: '/internal/receipt',
		icon: 'receipt',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksa laskulla',
				description: 'Title of an app section for users: invoice.',
			}),
		component: InternalInvoice,
		path: '/internal/invoice',
		icon: 'shopping_cart',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		public: true,
	},

	// Company
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Talosivu',
				description:
					'Title of an app section for users: housing company info page.',
			}),
		component: CompanySite,
		path: '/:company/',
		relativePath: '/',
		exact: true,
		icon: 'home',
		category: 'hidden',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		useCompanyColor: true,
		public: true,
		feature: 'site',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Ilmoitustaulu',
				description: 'Title of an app section for users: bulletinboard.',
			}),
		component: CompanyBulletinboard,
		path: '/:company/bulletinboard',
		relativePath: '/bulletinboard',
		icon: 'dashboard',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		feature: 'bulletins',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Porrasnäytön asettelun muokkaus',
				description: 'Title of an app section for editing an infoscreen.',
			}),
		component: CompanyInfoscreenMosaic,
		path: '/internal/infoscreen/edit',
		category: 'hidden',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		lean: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Porrasnäyttö',
				description: 'Title of an app section for users: infoscreen.',
			}),
		component: CompanyInfoscreen,
		path: '/internal/infoscreen',
		category: 'hidden',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		lean: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Keskustelut',
				description: 'Title of an app section for users: Conversations.',
			}),
		component: CompanyMessages,
		path: '/:company/messages',
		relativePath: '/messages',
		icon: 'chat_bubble',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		feature: 'messages',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asiakirjat',
				description: 'Title of an app section for users: Documents.',
			}),
		component: CompanyDocuments,
		path: '/:company/documents',
		relativePath: '/documents',
		icon: 'insert_drive_file',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Yhteiset',
				description: 'Title of an app section for users: Shared documents.',
			}),
		component: CompanyDocumentsPageCompany,
		path: '/:company/documents_company',
		relativePath: '/documents_company',
		icon: 'home',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'documents',
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Huoneistosi',
				description:
					'Title of an app section for users: Apartment-specific documents.',
			}),
		component: CompanyDocumentsPageApartment,
		path: '/:company/documents_apartment',
		relativePath: '/documents_apartment',
		icon: 'apartment',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'documents',
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Hallituksen',
				description: 'Title of an app section for users: Board-only documents.',
			}),
		component: CompanyDocumentsPageBoard,
		path: '/:company/documents_board',
		relativePath: '/documents_board',
		icon: 'lock',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'documents',
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kaikki asiakirjat',
				description: 'Title of an app section for users: All documents.',
			}),
		component: CompanyDocumentsV2All,
		path: '/:company/documents_v2',
		relativePath: '/documents_v2',
		icon: 'folder',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Viimeisimmät asiakirjat',
				description: 'Title of an app section for users: Latest documents.',
			}),
		component: CompanyDocumentsV2Latest,
		path: '/:company/documents_v2_latest',
		relativePath: '/documents_v2_latest',
		icon: 'folder',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asiakirjat',
				description: 'Title of an app section for users: Documents.',
			}),
		component: CompanyDocumentsV2Hub,
		path: '/:company/documents_v2_hub',
		relativePath: '/documents_v2_hub',
		icon: 'folder',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kokouksien arkistot',
				description:
					'Title of an app section for users: Documents archived from meetings.',
			}),
		component: CompanyDocumentsPageMeetingArchives,
		path: '/:company/documents_meeting_archives',
		relativePath: '/documents_meeting_archives',
		icon: 'archive',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'documents',
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Varaukset',
				description:
					'Title of an app section for users: Turnlists / Reservations.',
			}),
		component: CompanyTurnlistList,
		path: '/:company/turnlists',
		relativePath: '/turnlists',
		icon: 'today',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'turnlists',
		maintenanceCompanyHasAccess: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Varaukset',
				description:
					'Title of an app section for users: Turnlists / Reservations.',
			}),
		component: CompanyTurnlistList,
		path: '/:company/turnlist_list',
		relativePath: '/turnlist_list',
		icon: 'today',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		disabled: false,
		feature: 'turnlists',
		maintenanceCompanyHasAccess: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Varaukset',
				description:
					'Title of an app section for users: Turnlists / Reservations.',
			}),
		component: CompanyTurnlist,
		path: '/:company/turnlist',
		relativePath: '/turnlist',
		icon: 'today',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'turnlists',
		maintenanceCompanyHasAccess: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Jonot',
				description: 'Title of an app section for users: Queues.',
			}),
		component: CompanyQueues,
		path: '/:company/queues',
		relativePath: '/queues',
		icon: 'format_list_numbered',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		disabled: false,
		feature: 'queues',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Autopaikat',
				description: 'Title of an app section for users: Parking spaces.',
			}),
		component: CompanyParkingSpaces,
		path: '/:company/parking_spaces',
		exact: true,
		relativePath: '/parking_spaces',
		icon: 'directions_car',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		disabled: false,
		feature: 'parkingSpaces',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Autopaikat',
				description: 'Title of an app section for users: Parking spaces intro.',
			}),
		component: CompanyParkingSpacesIntro,
		path: '/:company/parking_spaces_intro',
		exact: true,
		relativePath: '/parking_spaces_intro',
		icon: 'directions_car',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'parkingSpaces',
	},
	{
		component: CompanyParkingSpacesReserve,
		path: '/:company/parking_spaces/reserve',
		relativePath: '/parking_spaces/reserve',
		icon: 'directions_car',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		hidden: true,
		disabled: false,
		feature: 'parkingSpaces',
	},
	{
		component: CompanyParkingSpacesQueue,
		path: '/:company/parking_spaces/queue',
		relativePath: '/parking_spaces/queue',
		icon: 'directions_car',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		hidden: true,
		disabled: false,
		feature: 'parkingSpaces',
	},
	{
		component: CompanyParkingSpacesTerminate,
		path: '/:company/parking_spaces/terminate',
		relativePath: '/parking_spaces/terminate',
		icon: 'directions_car',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		hidden: true,
		disabled: false,
		feature: 'parkingSpaces',
	},

	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Varastotilat',
				description: 'Title of an app section for users: Storage units.',
			}),
		component: CompanyStorageUnits,
		path: '/:company/storage_units',
		exact: true,
		relativePath: '/storage_units',
		icon: 'shelves',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		disabled: false,
		feature: 'storageUnits',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Varastotilat',
				description: 'Title of an app section for users: Storage units intro.',
			}),
		component: CompanyStorageUnitsIntro,
		path: '/:company/storage_units_intro',
		exact: true,
		relativePath: '/storage_units_intro',
		icon: 'shelves',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'storageUnits',
	},
	{
		component: CompanyStorageUnitsReserve,
		path: '/:company/storage_units/reserve',
		relativePath: '/storage_units/reserve',
		icon: 'shelves',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		hidden: true,
		disabled: false,
		feature: 'storageUnits',
	},
	{
		component: CompanyStorageUnitsQueue,
		path: '/:company/storage_units/queue',
		relativePath: '/storage_units/queue',
		icon: 'shelves',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		hidden: true,
		disabled: false,
		feature: 'storageUnits',
	},
	{
		component: CompanyStorageUnitsTerminate,
		path: '/:company/storage_units/terminate',
		relativePath: '/storage_units/terminate',
		icon: 'shelves',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		hidden: true,
		disabled: false,
		feature: 'storageUnits',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoituta',
				description:
					'Title of an app section for users: Request document signature.',
			}),
		component: CompanyDocumentSign,
		path: '/:company/document_sign',
		relativePath: '/document_sign',
		icon: 'edit',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoituta',
				description:
					'Title of an app section for users: Request document signature.',
			}),
		component: CompanyDocumentSign,
		path: '/manager/document_sign_internal',
		icon: 'edit',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'manager-hidden',
		disabled: false,
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoituta',
				description:
					'Title of an app section for users: Request document signature.',
			}),
		component: CompanyDocumentSign,
		path: '/internal/document_sign',
		relativePath: '/document_sign',
		icon: 'edit',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Vahvista allekirjoitus',
				description: 'Title of an app section for users: Confirm signature.',
			}),
		component: InternalDocumentCheck,
		path: '/internal/document_check',
		relativePath: '/document_check',
		icon: 'edit',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Vahvista allekirjoitus',
				description: 'Title of an app section for users: Confirm signature.',
			}),
		component: InternalDocumentVerify,
		path: '/internal/document_verify',
		relativePath: '/document_verify',
		icon: 'edit',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		public: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoituta',
				description:
					'Title of an app section for users: Request document signature.',
			}),
		component: CompanySignatureRequest,
		path: '/internal/company_signature_request',
		relativePath: '/company_signature_request',
		icon: 'edit',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'documents',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoituta',
				description:
					'Title of an app section for users: Request contract signature.',
			}),
		component: ManagerContractSign,
		path: '/manager/contract_sign_internal',
		icon: 'edit',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'manager-hidden',
		disabled: false,
		feature: 'documents',
	},

	// Forms
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lomake',
				description: 'Title of an app section for users: Form listing.',
			}),
		component: CompanyFormPublic,
		path: '/:company/form/public',
		relativePath: '/form/public',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		feature: 'forms',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lomake',
				description: 'Title of an app section for users: Form listing.',
			}),
		component: CompanyFormResponses,
		path: '/:company/form',
		relativePath: '/form',
		icon: 'description',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'forms',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Ilmoitukset ja tilaukset',
				description:
					'Title of an app section for users: Listing of available form types.',
			}),
		component: CompanyFormList,
		path: '/:company/formlist',
		relativePath: '/formlist',
		icon: 'description',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		disabled: false,
		feature: 'forms',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Talous',
				description: 'Title of an app section for users: Financials.',
			}),
		component: CompanyFinancials,
		path: '/:company/financials',
		relativePath: '/financials',
		icon: 'euro',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'actions',
		disabled: false,
		feature: 'financials',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Talous',
				description: 'Title of an app section for users: Apartment financials.',
			}),
		component: CompanyApartmentFinancials,
		path: '/:company/financials_apartment',
		relativePath: '/financials_apartment',
		icon: 'euro',
		color: 'var(--color-primary)',
		colorDark: 'var(--color-primary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'financials',
	},

	// Board
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kokoukset',
				description:
					'Title of an app section for users: List of company meetings.',
			}),
		component: CompanyMeetings,
		path: '/:company/meetings',
		relativePath: '/meetings',
		icon: 'videocam',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		disabled: false,
		feature: 'meetings',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tehtävienhallinta',
				description:
					'Title of an app section for users: Company task management.',
			}),
		component: CompanyTaskManager,
		path: '/:company/tasks',
		relativePath: '/tasks',
		icon: 'check_circle',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		disabled: false,
		feature: 'tasks',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Huoltokirja',
				description:
					'Title of an app section for users: Company maintenance book.',
			}),
		component: CompanyMaintenanceBook,
		path: '/:company/maintenance_book',
		relativePath: '/maintenance_book',
		icon: 'construction',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		feature: 'maintenanceBook',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tehtävienhallinta',
				description:
					'Title of an app section for users: Company task management.',
			}),
		component: CompanyTaskManager,
		path: '/:company/tasklists',
		relativePath: '/tasklists',
		icon: 'format_list_bulleted',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'tasks',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Ilmoitukset',
				description:
					'Title of an app section for users: listing of all bulletins.',
			}),
		component: CompanyBulletins,
		path: '/:company/bulletins',
		relativePath: '/bulletins',
		icon: 'notifications',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		disabled: false,
		feature: 'bulletins',
		maintenanceCompanyHasAccess: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asukaskyselyt',
				description:
					'Title of an app section for users: listing of all surveys.',
			}),
		component: CompanySurveys,
		path: '/:company/surveys',
		relativePath: '/surveys',
		icon: 'timeline',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		disabled: false,
		feature: 'surveys',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Käyttäjät',
				description: 'Title of an app section for users: listing of all users.',
			}),
		component: CompanyUsers,
		path: '/:company/users',
		relativePath: '/users',
		icon: 'group',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		disabled: false,
		feature: 'users',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Huoneistot',
				description:
					'Title of an app section for users: listing of all apartments.',
			}),
		component: CompanyApartments,
		path: '/:company/apartments',
		relativePath: '/apartments',
		icon: 'apartment',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		disabled: false,
		feature: 'apartments',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Rakennukset',
				description:
					'Title of an app section for users: listing of all buildings.',
			}),
		component: CompanyBuildings,
		path: '/:company/buildings',
		relativePath: '/buildings',
		icon: 'domain',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		disabled: false,
		feature: 'buildings',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Autopaikkarekisteri',
				description: 'Title of an app section for parking space registry.',
			}),
		component: CompanyParkingSpaceRegistry,
		path: '/:company/parking_space_registry',
		relativePath: '/parking_space_registry',
		icon: 'directions_car',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		feature: 'parkingSpaces',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Varastotilarekisteri',
				description: 'Title of an app section for storage unit registry.',
			}),
		component: CompanyStorageUnitRegistry,
		path: '/:company/storage_unit_registry',
		relativePath: '/storage_unit_registry',
		icon: 'shelves',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		feature: 'storageUnits',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Ilmoitukset ja tilaukset',
				description: 'Title of an app section for users: Listing of forms.',
			}),
		component: CompanyForms,
		path: '/:company/forms',
		relativePath: '/forms',
		icon: 'description',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		disabled: false,
		feature: 'forms',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Porrasnäytöt',
				description: 'Title of an app section for users: Company infoscreens.',
			}),
		component: CompanyInfoscreens,
		path: '/:company/infoscreens',
		relativePath: '/infoscreens',
		icon: 'perm_device_information',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		feature: 'infoscreens',
		maintenanceCompanyHasAccess: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kulunohjaus',
				description: 'Title of an app section for users: Company smartlocks.',
			}),
		component: CompanySmartlocks,
		path: '/:company/smartlocks',
		relativePath: '/smartlocks',
		icon: 'key',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		feature: 'smartlocks',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kulunohjaustapahtumat',
				description:
					'Title of an app section for users: Company smartlock entries.',
			}),
		component: CompanySmartlockEntries,
		path: '/:company/smartlock_entries',
		relativePath: '/smartlock_entries',
		icon: 'key',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		feature: 'smartlocks',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kulunohjaushälytykset',
				description:
					'Title of an app section for users: Company smartlock incidents.',
			}),
		component: CompanySmartlockIncidents,
		path: '/:company/smartlock_incidents',
		relativePath: '/smartlock_incidents',
		icon: 'key',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		feature: 'smartlocks',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Viestintäpalvelu',
				description: 'Title of an app section for users: Message Service.',
			}),
		component: MessageService,
		path: '/:company/message_service',
		relativePath: '/message_service',
		icon: 'mail',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		feature: 'messageService',
		maintenanceCompanyHasAccess: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kiinteistötiedot',
				description:
					'Title of an app section for property managers: Property information.',
			}),
		component: PropertyInformation,
		path: '/:company/property_information',
		relativePath: '/property_information',
		icon: 'house',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'board',
		feature: 'propertyInformation',
		public: true,
		maintenanceCompanyHasAccess: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Korjaussuunnitelma',
				description:
					'Title of an app section for property managers: Administrative information.',
			}),
		component: CompanyAdministrativeInformation,
		path: '/:company/administrative_information',
		relativePath: '/administrative_information',
		icon: 'house',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'board',
		feature: 'administrativeInformation',
		public: true,
		maintenanceCompanyHasAccess: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Viesti',
				description:
					'Title of an app section for users: Message Service message.',
			}),
		component: MessageServiceUnauthenticatedView,
		path: '/internal/message',
		icon: 'mail',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		feature: 'messageService',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asetukset',
				description: 'Title of an app section for users: Company settings.',
			}),
		component: CompanySettings,
		path: '/:company/settings',
		relativePath: '/settings',
		icon: 'settings',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'board',
		feature: 'settings',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Käyttöperusteiset ominaisuudet',
				description:
					'Title of a report for companies: Usage based features (billing).',
			}),
		component: CompanyReportsCompanyFeature,
		path: '/:company/reports/company_features',
		icon: 'insert_chart_outlined',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		disabled: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Hallintotyökalut',
				description: 'Title of an app section for users: Management tools.',
			}),
		component: CompanyBoardFeatures,
		path: '/:company/board_features',
		relativePath: '/board_features',
		icon: 'settings',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
	},

	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tuotteet',
				description: 'Title of an app section for admins: Products.',
			}),
		component: CompanyProducts,
		path: '/:company/products',
		relativePath: '/products',
		icon: 'category',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'payments',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksutapahtumat',
				description: 'Title of an app section for property admins: Payments.',
			}),
		component: CompanyPayments,
		path: '/:company/payments',
		relativePath: '/payments',
		icon: 'receipt',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'hidden',
		disabled: false,
		feature: 'payments',
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Estolista',
				description: 'Title of an app section for managers and admin: Banlist',
			}),
		component: CompanyBanlist,
		path: '/:company/banlist',
		relativePath: '/banlist',
		icon: 'receipt',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		disabled: false,
	},

	// Viilu internal
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Käyttäjät',
				description:
					'Title of an app section for admins: Listing of all users.',
			}),
		component: ConfidentialUsers,
		path: '/internal/admin_users',
		icon: 'group',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kohteet',
				description:
					'Title of an app section for admins: Listing of all companies.',
			}),
		component: ConfidentialCompanies,
		path: '/internal/admin_companies',
		icon: 'home',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		feature: 'companies',
		restricted: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Postituspalvelu',
				description:
					'Title of an app section for admins: Listing of all postal batches.',
			}),
		component: ConfidentialPostalBatches,
		path: '/internal/admin_postal_batches',
		icon: 'mail',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Massapäivitykset',
				description: 'Title of an app section for admins: Mass updates.',
			}),
		component: ConfidentialMassUpdate,
		path: '/internal/admin_mass_update',
		icon: 'settings',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tarjoustyökalu',
				description: 'Title of an app section for admins: Contract proposals.',
			}),
		component: ConfidentialContractProposals,
		path: '/internal/contract_proposals',
		icon: 'description',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kohteiden massatuonti',
				description:
					'Title of an app section for admins: Mass import of companies.',
			}),
		component: ManagerCompanyImport,
		path: '/manager/company_import',
		icon: 'home',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Käyttäjien massatuonti',
				description:
					'Title of an app section for admins: Mass import of users.',
			}),
		component: ManagerCompanyUserImport,
		path: '/manager/company_user_import',
		icon: 'group_add',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tilastot',
				description: 'Title of an app section for admins: Stats.',
			}),
		component: ConfidentialStats,
		path: '/internal/admin_stats',
		icon: 'query_stats',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Sopimuslaskutus',
				description: 'Title of an app section for admins: SaaS Invoicing.',
			}),
		component: ConfidentialInvoicingSaaS,
		path: '/internal/admin_invoicing_saas',
		icon: 'euro',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Allekirjoitusten laskutus',
				description: 'Title of an app section for admins: Signing Invoicing.',
			}),
		component: ConfidentialInvoicingSigning,
		path: '/internal/admin_invoicing_signing',
		icon: 'euro',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Postitusten laskutus',
				description: 'Title of an app section for admins: Postal Invoicing.',
			}),
		component: ConfidentialInvoicingPostal,
		path: '/internal/admin_invoicing_postal',
		icon: 'euro',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Tekstiviestien laskutus',
				description: 'Title of an app section for admins: SMS Invoicing.',
			}),
		component: ConfidentialInvoicingSMS,
		path: '/internal/admin_invoicing_sms',
		icon: 'euro',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kohteet kartalla',
				description: 'Title of an app section for admins: companies on a map.',
			}),
		component: ConfidentialCompaniesMap,
		path: '/internal/admin_companies_map',
		icon: 'map',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Porrasnäytöt',
				description: 'Title of an app section for admins: Infoscreens.',
			}),
		component: ConfidentialInfoscreens,
		path: '/internal/admin_infoscreens',
		icon: 'perm_device_information',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Kokouspalvelu',
				description: 'Title of an app section for admins: Meeting service.',
			}),
		component: ConfidentialMeetings,
		path: '/internal/admin_meetings',
		icon: 'videocam',
		color: 'var(--color-secondary)',
		colorDark: 'var(--color-secondary-dark)',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Fivaldi asiakkaat',
				description: 'Title of an app section for admins: Fivaldi customers.',
			}),
		component: ConfidentialFivaldiCustomers,
		path: '/manager/fivaldi_customers',
		icon: 'import_export',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Oiva 360',
				description: 'Title of an app section for admins: Oiva 360.',
			}),
		component: ConfidentialOiva360,
		path: '/manager/oiva360',
		icon: 'import_export',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asiakaskanta',
				description: 'Title of an app section for admins: Customers.',
			}),
		component: ConfidentialCustomerbase,
		path: '/manager/confidential_customerbase',
		icon: 'work',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksutapahtumat',
				description: 'Title of an app section for admins: payments.',
			}),
		component: ConfidentialPayments,
		path: '/manager/admin_payments',
		icon: 'receipt',
		color: '#6B849A',
		colorDark: '#385167',
		category: 'manager',
		disabled: false,
		internal: true,
		restricted: true,
	},
	// UI components
	{
		title: 'Buttons',
		component: ButtonsForTesting,
		path: '/ui/buttons',
		internal: true,
	},
	{
		title: 'Notistacks',
		component: TestSnackbar,
		path: '/ui/notistacks',
		internal: true,
	},
]

export default routes
