import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEffect, useMemo, useState } from 'react'
import { breakpoint } from 'ui/StyledComponents/MediaBreakpoints'
import LoanCreateForm from './LoanCreateForm'
import type { SelectOptions } from '../../types'
import type {
	Loan,
	LoanCreateData,
	LoanCreateFormInputs,
	LoanShareholderCreateData,
	LoanApartmentWithShareholderFields,
} from '../types'
import * as notifyActions from 'state/notifyActions'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import {
	createLoanDefaultValues,
	formStepToInputsMap,
	loanFinancingTypeInput,
	STEPS_FOR_CAPITAL_LOAN,
	STEPS_FOR_NON_CAPITAL_LOAN,
} from '../constants'
import { _createLoan, _updateLoan } from 'state/finance-loan.actions'
import { Button } from 'react-md'
import { PaymentProductBillingBasis } from 'ui/ManagerFinance/enum'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import Stepper from 'ui/MessageService/MessageServiceProcess/Stepper'
import LoanCreateBasicInfoForm from './LoanCreateBasicInfoForm'
import LoanCreateShareGroupsForm from './LoanCreateShareGroupsForm'
import LoanFormInput from './LoanFormInput'
import {
	convertEmptyStringsToNull,
	mapLoanToFormInputs,
	mapShareholdersToSelectableApartments,
} from '../utils'
import ViiluFullPageDialog, { Action } from 'ui/components/ViiluFullPageDialog'
import { LoanStatus } from '../enum'
import LoanCreatePaymentProduct from './LoanCreatePaymentProduct'

const ContentContainer = styled.div`
	display: flex;
	flex-flow: column nowrap;
	height: 100%;

	@media ${breakpoint.desktop} {
		flex-flow: row nowrap;
	}
`
const MainContentContainer = styled.section`
	width: 100%;
`

const StepContentContainer = styled.div`
	padding: 1rem;
`

type LoanCreateDialogProps = {
	onHide: () => void
	companyOptions: SelectOptions[]
	refreshLoans: () => void
	loan?: Loan
}

function LoanCreateDialog(props: LoanCreateDialogProps) {
	const { onHide, companyOptions, loan, refreshLoans } = props
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const { companyApartments } = useCompanyApartments(selectedFinanceCompanyUUID)
	const dispatch = useDispatch()
	const intl = useIntl()
	const [processing, setProcessing] = useState(false)
	const [formStep, setFormStep] = useState(
		loan?.currentFormStep ? loan.currentFormStep : 0,
	)
	const [reachedStepIndex, setReachedStepIndex] = useState(
		loan?.reachedFormStep ? loan.reachedFormStep : 0,
	)
	const [steps, setSteps] = useState(STEPS_FOR_CAPITAL_LOAN(intl))

	const mappedSelectableApartments = useMemo(() => {
		const selected = mapShareholdersToSelectableApartments(
			companyApartments,
			loan?.shareholders,
		)
		return selected
	}, [companyApartments, loan?.shareholders])

	const formMethods = useForm<LoanCreateFormInputs>({
		defaultValues: {
			...(loan
				? {
						...mapLoanToFormInputs(loan),
						selectedSharegroups: mappedSelectableApartments,
				  }
				: createLoanDefaultValues),
			companyUUID: selectedFinanceCompanyUUID,
		},
		mode: 'onChange',
	})

	const [loanFinancingType, loanAmount, debtAmount] = formMethods.watch([
		'loanFinancingType',
		'originalLoanAmount',
		'debtLeftToPay',
	])

	// Use useWatch hooks to watch for these fields so that
	// totalSelectedUnits can be properly re-computed even when nested object changes
	const watchedSelectedApartments = useWatch({
		control: formMethods.control,
		name: 'selectedSharegroups',
	})

	const watchedBillingBasis = useWatch({
		control: formMethods.control,
		name: 'billingBasis',
	})

	useEffect(() => {
		// In case we open a draft loan that is still in progress,
		// we need to reset the form to take the mapped apartments/share groups
		// that have been computed after form initialization
		if (Object.values(mappedSelectableApartments).length > 0) {
			formMethods.reset((prevValues) => ({
				...prevValues,
				selectedSharegroups: mappedSelectableApartments,
			}))
		}
	}, [mappedSelectableApartments, formMethods])

	useEffect(() => {
		if (loanFinancingType !== 'capital') {
			setSteps(STEPS_FOR_NON_CAPITAL_LOAN(intl))
		} else setSteps(STEPS_FOR_CAPITAL_LOAN(intl))
	}, [loanFinancingType, intl])

	const selectedApartments = useMemo(() => {
		return watchedSelectedApartments || {}
	}, [watchedSelectedApartments])

	const billingBasis = useMemo(() => {
		return watchedBillingBasis || ''
	}, [watchedBillingBasis])

	const totalSelectedUnits = useMemo(() => {
		const selectedApartmentsData = Object.values(selectedApartments)
		return selectedApartmentsData.reduce((acc, apt) => {
			switch (billingBasis) {
				case PaymentProductBillingBasis.AREA:
					return acc + (apt?.squaresAmount || 0)
				case PaymentProductBillingBasis.AREA_ADJUSTED:
					return acc + (apt?.weightedSquaresAmount || 0)
				case PaymentProductBillingBasis.SHARE_COUNT:
					return acc + (apt?.shareCount || 0)
				case PaymentProductBillingBasis.RESIDENT_COUNT:
					return acc + (apt?.userUUIDs?.length || 0)
				case PaymentProductBillingBasis.PIECES:
					return acc
				default:
					return acc
			}
		}, 0)
	}, [selectedApartments, billingBasis])

	const loanApartments = useMemo(() => {
		const newApartments: LoanApartmentWithShareholderFields[] =
			companyApartments.map((apartment) => {
				const apartmentSelected = selectedApartments[apartment.uuid]
				let shareUnits = 0

				switch (billingBasis) {
					case PaymentProductBillingBasis.AREA:
						shareUnits = apartment.squaresAmount || 0
						break
					case PaymentProductBillingBasis.AREA_ADJUSTED:
						shareUnits = apartment.weightedSquaresAmount || 0
						break
					case PaymentProductBillingBasis.SHARE_COUNT:
						shareUnits = apartment.shareCount || 0
						break
					case PaymentProductBillingBasis.RESIDENT_COUNT:
						shareUnits = apartment.userUUIDs?.length || 0
						break
					case PaymentProductBillingBasis.PIECES:
						shareUnits = 0
						break
					default:
						shareUnits = 0
				}

				// For now separate loan and debt share calculations, these might change in the future
				const loanShareAmount =
					apartmentSelected && totalSelectedUnits > 0
						? (shareUnits / totalSelectedUnits) * loanAmount
						: 0
				const debtShareAmount =
					apartmentSelected && totalSelectedUnits > 0
						? (shareUnits / totalSelectedUnits) * debtAmount
						: 0
				return {
					...apartment,
					loanShareAmount,
					debtShareAmount,
					units: shareUnits,
					billingBasis: billingBasis,
				}
			})
		return newApartments
	}, [
		billingBasis,
		companyApartments,
		debtAmount,
		loanAmount,
		selectedApartments,
		totalSelectedUnits,
	])

	const modifyLoan = async (
		data: LoanCreateFormInputs,
		isActive: boolean,
		updateLoan: boolean,
	) => {
		const companyName = companyOptions.find(
			(option) => option.value === data.companyUUID,
		)?.label
		const sanitizedData = convertEmptyStringsToNull(data)

		const shareholders: LoanShareholderCreateData[] = Object.values(
			data.selectedSharegroups,
		)
			.filter((apartment) => apartment !== undefined)
			.map((apartment) => {
				const correspondingSelectedApartment = loanApartments.find(
					(selectedApartment) => selectedApartment.uuid === apartment?.uuid,
				)
				return {
					loanName: sanitizedData.name,
					currency: 'EUR',
					units: correspondingSelectedApartment?.units || 0,
					billingBasis: data.billingBasis,
					companyName: sanitizedData.companyName,
					companyUUID: sanitizedData.companyUUID,
					shareGroupUUID: correspondingSelectedApartment?.uuid || '',
					loanShareAmount: correspondingSelectedApartment?.loanShareAmount || 0,
					debtShareAmount: correspondingSelectedApartment?.debtShareAmount || 0,
				}
			})

		// Construct the final loan object that will be sent to backend
		const createLoanData: LoanCreateData = {
			name: sanitizedData.name,
			creditId: sanitizedData.creditId,
			status: isActive ? LoanStatus.ACTIVE : LoanStatus.DRAFT,
			currency: 'EUR',
			companyUUID: sanitizedData.companyUUID,
			companyName: companyName || '',
			loanFinancingType: sanitizedData.loanFinancingType,
			loanType: sanitizedData.loanType,
			loanGrantedBy: sanitizedData.loanGrantedBy,
			loanTime: sanitizedData.loanTime,
			loanStartDateEstimated: sanitizedData.loanStartDateEstimated,
			loanEndDateEstimated: sanitizedData.loanEndDateEstimated,
			loanStartDate: sanitizedData.loanStartDate,
			loanEndDate: sanitizedData.loanEndDate,
			originalLoanAmount: sanitizedData.originalLoanAmount,
			loanDrawnAmount: sanitizedData.loanDrawnAmount,
			loanFirstDrawDate: sanitizedData.loanFirstDrawDate,
			debtLeftToPay: sanitizedData.debtLeftToPay,
			loanSharesCanBePaidOff: sanitizedData.loanSharesCanBePaidOff,
			loanSharePaymentDates: sanitizedData.loanSharePaymentDates,
			usableOnBuildingManagerCertificate:
				sanitizedData.usableOnBuildingManagerCertificate,
			loanDrawn: false,
			interestDaysMonth: sanitizedData.interestDaysMonth,
			interestDaysYear: sanitizedData.interestDaysYear,
			interestType: sanitizedData.interestType,
			interestPercentage: sanitizedData.interestPercentage,
			interestLastUpdated: sanitizedData.interestLastUpdated,
			interestFirstDueDate: sanitizedData.interestFirstDueDate,
			interestInspectionDates: sanitizedData.interestInspectionDates,
			marginalPercentage: sanitizedData.marginalPercentage,
			amortizationInterval: sanitizedData.amortizationInterval,
			amortizationAmount: sanitizedData.amortizationAmount,
			amortizationFirstDueDate: sanitizedData.amortizationFirstDueDate,
			amortizationDates: sanitizedData.amortizationDates,
			units: sanitizedData.units,
			billingBasis: sanitizedData.billingBasis,
			currentFormStep: !isActive ? formStep : undefined,
			reachedFormStep: !isActive ? reachedStepIndex : undefined,
			shareholders: shareholders,
		}

		try {
			let result
			if (updateLoan && loan?.uuid) {
				result = await _updateLoan(loan.uuid, createLoanData)
			} else {
				result = await _createLoan(createLoanData)
			}
			refreshLoans()
			if (result?.ok) {
				dispatch(
					notifyActions.success(
						updateLoan && !isActive
							? intl.formatMessage({
									defaultMessage: 'Lainan luonnos tallennettiin onnistuneesti.',
									description:
										'Notification message when draft loan has been succesfully saved',
							  })
							: intl.formatMessage({
									defaultMessage: 'Laina luotiin onnistuneesti.',
									description:
										'Notification message when loan has been succesfully created',
							  }),
					),
				)
				onHide()
			} else {
				dispatch(
					notifyActions.error(
						updateLoan && !isActive
							? intl.formatMessage({
									defaultMessage: 'Lainan luonnoksen tallennus ei onnistunut.',
									description:
										'Notification message when there was error during draft loan saving',
							  })
							: intl.formatMessage({
									defaultMessage: 'Lainan luonti ei onnistunut.',
									description:
										'Notification message when there was error during loan creation',
							  }),
					),
				)
				throw new Error('Response is not ok.')
			}
		} catch (error) {
			console.error(error)
			setProcessing(false)
		}
	}

	const onDraftSave = async () => {
		const currentData = formMethods.getValues()
		if (loan) {
			// Update loan
			modifyLoan(currentData, false, true)
		} else {
			// Create loan as draft
			modifyLoan(currentData, false, false)
		}
	}

	const onSubmit = formMethods.handleSubmit(async (data) => {
		setProcessing(true)
		if (loan) modifyLoan(data, true, true)
		else modifyLoan(data, true, false)
	})

	const moveToStep = async (stepIndex: number) => {
		// If we are moving to a higher step, validate the form to prevent
		// user from moving on with invalid fields
		if (stepIndex > formStep) {
			const isStepValid = await formMethods.trigger(
				formStepToInputsMap[formStep],
			)
			if (isStepValid) {
				setFormStep(stepIndex)
				if (reachedStepIndex < stepIndex) {
					setReachedStepIndex(stepIndex)
				}
			}
		} else {
			// We are moving to a lower step, dont need to validate
			setFormStep(stepIndex)
		}
	}

	const renderLoanFinancialTypeStep = () => {
		return (
			<StepContentContainer>
				<LoanFormInput input={loanFinancingTypeInput} />
			</StepContentContainer>
		)
	}

	const renderMainElement = () => {
		return (
			<MainContentContainer>
				<h2
					className="md-title"
					style={{ marginBottom: '0', padding: '1rem 1.5rem' }}
				>
					{steps[formStep].label}

					<hr style={{ borderColor: 'rgba(0, 0, 0, 0.2)' }} />
				</h2>
				<FormProvider {...formMethods}>
					{formStep === 0 && renderLoanFinancialTypeStep()}
					{formStep === 1 && (
						<LoanCreateBasicInfoForm companyOptions={companyOptions} />
					)}
					{formStep === 2 && <LoanCreateForm />}
					{formStep === 3 && (
						<LoanCreateShareGroupsForm
							companyOptions={companyOptions}
							loanApartments={loanApartments}
						/>
					)}
					{formStep === 4 && <LoanCreatePaymentProduct />}
				</FormProvider>
			</MainContentContainer>
		)
	}

	const renderActions = () => {
		const actions: Array<Action | undefined> = [
			formStep > 0
				? {
						id: 'create-loan-form-back',
						onClick: () => setFormStep(formStep > 0 ? formStep - 1 : 0),
						align: 'right',
						children: (
							<FormattedMessage
								defaultMessage="Takaisin"
								description="Back button"
							/>
						),
				  }
				: undefined,
			formStep > 0
				? {
						id: 'create-loan-form-save-draft',
						onClick: onDraftSave,
						secondary: true,
						align: 'left',
						children: (
							<FormattedMessage
								defaultMessage="Tallenna luonnos"
								description="Save as draft button"
							/>
						),
				  }
				: undefined,
			formStep !== steps.length - 1
				? {
						id: 'create-loan-form-next',
						raised: true,
						secondary: true,
						align: 'right',
						onClick: () =>
							moveToStep(formStep < steps.length - 1 ? formStep + 1 : formStep),
						children: (
							<FormattedMessage
								defaultMessage="Seuraava"
								description="Next step button"
							/>
						),
				  }
				: {
						id: 'create-loan-form-save',
						raised: true,
						secondary: true,
						align: 'right',
						onClick: onSubmit,
						disabled: processing,
						children: (
							<FormattedMessage
								defaultMessage="Luo laina"
								description="Create loan button"
							/>
						),
				  },
		]
		const filteredActions = actions.filter(
			(action): action is Action => action !== undefined,
		)
		return filteredActions
	}

	return (
		<ViiluFullPageDialog
			id="loan-view-dialog"
			title={intl.formatMessage({
				defaultMessage: 'Luo uusi laina',
				description: 'Title for loan create dialog',
			})}
			actions={renderActions()}
			visible={true}
			onHide={onHide}
			focusOnMount={false}
			containFocus={true}
			nav={
				<Button
					icon
					onClick={() => {
						onHide()
					}}
				>
					close
				</Button>
			}
			toolbarStyle={{ background: 'var(--color-secondary-dark)' }}
			navActions={
				<Stepper
					steps={steps}
					goToStep={({ id }) =>
						moveToStep(steps.findIndex((step) => step.id === id) || 0)
					}
					activeStepIndex={formStep}
					reachedStepIndex={reachedStepIndex}
				/>
			}
		>
			<ContentContainer>{renderMainElement()}</ContentContainer>
		</ViiluFullPageDialog>
	)
}

export default LoanCreateDialog
