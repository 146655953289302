import type { IntlShape } from 'react-intl'
import type { InvoiceHeader } from './types'

export const invoiceHeaders: InvoiceHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Table column header for finance invoice apartment.',
			}),
		key: 'apartmentIdentifier',
		show: true,
		sortable: true,
		type: 'apartmentOptions',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maksaja',
				description: 'Table column header for finance invoice payer.',
			}),
		key: 'partyName',
		show: true,
		sortable: true,
		type: 'string',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Tila',
				description: 'Table column header for finance invoice status.',
			}),
		key: 'status',
		show: true,
		sortable: true,
		type: 'invoiceStatusMessage',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskutustapa',
				description: 'Table column header for finance invoice method.',
			}),
		key: 'invoicingMethod',
		show: true,
		sortable: true,
		type: 'invoicingMethodMessage',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskunumero',
				description: 'Table column header for finance invoice number.',
			}),
		key: 'invoiceNumber',
		show: true,
		sortable: true,
		type: 'string',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Eräpäivä',
				description: 'Table column header for finance invoice due date.',
			}),
		key: 'dueDate',
		show: true,
		sortable: true,
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskupäivä',
				description: 'Table column header for finance invoice date.',
			}),
		key: 'invoiceDate',
		show: true,
		sortable: true,
		type: 'date',
	},

	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Suoritukset',
				description: 'Table column header for finance invoice payments.',
			}),
		key: 'paymentsTotal',
		show: true,
		sortable: true,
		type: 'currency',
		style: 'getPaymentsTotalStyle',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Summa + alv',
				description: 'Table column header for finance invoice total with vat.',
			}),
		key: 'totalWithVat',
		show: true,
		sortable: true,
		type: 'currency',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Summa',
				description: 'Table column header for finance invoice total.',
			}),
		key: 'total',
		show: true,
		sortable: true,
		type: 'currency',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Viitenumero',
				description:
					'Table column header for finance invoice reference number.',
			}),
		key: 'referenceNumber',
		show: true,
		sortable: true,
		type: 'string',
	},
]

export const invoiceCreateHeaders = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Asunto',
				description: 'apartmentUUID',
			}),
		key: 'apartmentUUID',
		show: true,
		type: 'text',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maksaja',
				description: 'partyName',
			}),
		key: 'partyName',
		show: true,
		type: 'text',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maksajan roolit',
				description: 'partyBonds',
			}),
		key: 'partyBonds',
		show: true,
		type: 'partyBonds',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Toinen maksaja',
				description: 'secondaryPartyName',
			}),
		key: 'secondaryPartyName',
		show: true,
		type: 'text',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Toisen maksajan roolit',
				description: 'secondaryPartyBonds',
			}),
		key: 'secondaryPartyBonds',
		show: true,
		type: 'partyBonds',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Viite',
				description: 'referenceNumber',
			}),
		key: 'referenceNumber',
		show: true,
		type: 'text',
	},
]

export const invoiceSingleCreateHeaders = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Nimike',
				description: 'Table header for invoice payment product name.',
			}),
		key: 'name',
		show: true,
		type: 'text',
		editable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Hinta',
				description: 'Table header for invoice payment product price.',
			}),
		key: 'price',
		show: true,
		type: 'number',
		editable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'ALV',
				description: 'Table header for invoice payment product vat.',
			}),
		key: 'vatUUID',
		show: true,
		type: 'select',
		editable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Hinta + alv',
				description: 'Table header for invoice payment product price with vat.',
			}),
		key: 'priceWithVat',
		show: true,
		type: 'number',
		editable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Määrä',
				description: 'Table header for invoice payment product amount.',
			}),
		key: 'quantity',
		show: true,
		type: 'number',
		editable: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Veloitusperuste',
				description: 'Table header for invoice payment product billing basis.',
			}),
		key: 'billingBasis',
		show: true,
		type: 'billingBasis',
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Yksikkö',
				description: 'Table header for invoice payment product unit.',
			}),
		key: 'unitMessage',
		show: true,
		type: 'unitMessage',
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Summa',
				description: 'Table header for invoice payment product total.',
			}),
		key: 'total',
		show: true,
		type: 'total',
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Summa + alv',
				description: 'Table header for invoice payment product total with vat.',
			}),
		key: 'totalWithVat',
		show: true,
		type: 'totalWithVat',
		editable: false,
	},
]

export const invoiceContractDetailsLabels = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Sopimus',
				description: 'Table column label for invoice contract name.',
			}),
		key: 'uuid',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kohde',
				description: 'Table column label for invoice company name.',
			}),
		key: 'companyUUID',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Table column label for invoice apartment id.',
			}),
		key: 'apartmentIdentifier',
		type: 'apartmentIdentifier',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maksaja',
				description: 'Table column label for invoice payer name.',
			}),
		key: 'partyName',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Toinen maksaja',
				description: 'Table column label for invoice second payer name.',
			}),
		key: 'secondaryPartyName',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskupäivä',
				description: 'Table column label for invoice date.',
			}),
		key: 'invoiceDate',
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Eräpäivä', // Due date
				description: 'Table column label for invoice due date.',
			}),
		key: 'dueDate',
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskutustapa',
				description: 'Table column label for invoice method.',
			}),
		key: 'partyInvoicingMethod',
		secondaryKey: 'invoicingMethod',
		type: 'invoicingMethodMessage',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Verkkolaskutusosoite',
				description: 'Table column label for electronic invoice address.',
			}),
		key: 'invoicingAddress',
		secondaryKey: 'partyInvoicingAddress',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Viitenumero',
				description: 'Table column label for invoice reference number.',
			}),
		key: 'referenceNumber',
	},
]

export const invoiceDetailsLabels = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Sopimus',
				description: 'Table column label for invoice contract name.',
			}),
		key: 'contractUUID',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Kohde',
				description: 'Table column label for invoice company name.',
			}),
		key: 'companyUUID',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Table column label for invoice apartment id.',
			}),
		key: 'apartmentIdentifier',
		type: 'apartmentIdentifier',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Maksaja',
				description: 'Table column label for invoice payer name.',
			}),
		key: 'partyName',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Toinen maksaja',
				description: 'Table column label for invoice second payer name.',
			}),
		key: 'secondPartyName',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskupäivä',
				description: 'Table column label for invoice date.',
			}),
		key: 'invoiceDate',
		type: 'formatDate',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Eräpäivä',
				description: 'Table column label for invoice due date.',
			}),
		key: 'dueDate',
		type: 'formatDate',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskunumero',
				description: 'Table column label for invoice number.',
			}),
		key: 'entity',
		type: 'formatInvoiceNumber',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Autokohdistuksen esto',
				description: 'Table column label for invoice automatic matching block.',
			}),
		key: 'automaticMatchingBlock',
		type: 'YES_NO_MESSAGE',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskutustapa',
				description: 'Table column label for invoice method.',
			}),
		key: 'invoicingMethod',
		type: 'invoicingMethodMessage',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Verkkolaskutusosoite',
				description: 'Table column label for electronic invoice address.',
			}),
		key: 'eInvoiceAddress',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Välittäjätunnus',
				description: 'Table column label for broker id.',
			}),
		key: 'brokerId',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Viitenumero',
				description: 'Table column label for invoice reference number.',
			}),
		key: 'referenceNumber',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Summa',
				description: 'Table column label for invoice total.',
			}),
		key: 'total',
		type: 'formatCurrency',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Summa + alv',
				description: 'Table column label for invoice total with vat.',
			}),
		key: 'totalWithVat',
		type: 'formatCurrency',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Avoinna',
				description: 'Table column label for invoice unpaid amount.',
			}),
		key: 'openBalance',
		type: 'formatCurrency',
	},
]
