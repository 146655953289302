import config from 'config'
import { push } from 'connected-react-router'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import LoadingState from 'ui/components/LoadingState'
import InvoiceCreateDialog from './InvoiceCreateDialog'
import InvoiceDialog from './InvoiceDialog'
import InvoicesTable from './InvoicesTable'
import useInvoices from './useInvoices'
import type { InvoiceFilters } from './types'
import Filters from './Filters'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import type { SelectOptions } from '../types'
import { InvoiceStatus, InvoicingMethod } from '../enum'
import { createApartmentOptions } from '../utils/apartmentOptions'
import { useBuildings } from 'ui/CompanyBuildings/hooks'
import { invoicingMethodMessage, invoiceStatusMessage } from '../utils/messages'
import useSettings from '../Settings/useSettings'
import usePaymentProducts from '../PaymentProducts/usePaymentProducts'
import FloatingActionBtnMenu from 'ui/components/FloatingActionBtn/FloatingActionBtnMenu'
import useValueAddedTaxes from '../ValueAddedTax/useValueAddedTaxes'
import { createVatOptions } from '../utils/vatOptions'

const Container = styled.div`
	padding: 16px;
`

const LoadingContainer = styled.div`
	margin-top: 64px;
`
const HASH_INVOICES = config.routeHashes.invoices
const HASH_CONSOLIDATED_INVOICES = config.routeHashes.consolidatedInvoice

const InvoiceView = () => {
	const intl = useIntl()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const queryParamUUID = useSelector(
		(state: any) => state.router?.location?.query?.uuid,
	)
	const consolidatedUUID = useSelector(
		(state: any) => state.router?.location?.query?.consolidatedUUID,
	)

	const { data: paymentProducts } = usePaymentProducts(
		selectedFinanceCompanyUUID,
	)
	const [filters, setFilters] = useState<InvoiceFilters>({
		apartmentUUID: null,
		invoiceDate: null,
		dueDate: null,
		invoicingMethod: null,
		status: InvoiceStatus.SENT,
		searchText: null,
	})
	const { settings } = useSettings(selectedFinanceCompanyUUID)
	const { buildings } = useBuildings(selectedFinanceCompanyUUID)
	const { companyApartments } = useCompanyApartments(selectedFinanceCompanyUUID)
	const apartmentOptions = createApartmentOptions(companyApartments, buildings)

	const { valueAddedTaxes } = useValueAddedTaxes()
	const vatOptions = createVatOptions(valueAddedTaxes || [])

	const invoicingOptions: SelectOptions[] = Object.values(InvoicingMethod).map(
		(value) => ({
			label: invoicingMethodMessage(intl, value),
			value: value,
		}),
	)

	const statusOptions: SelectOptions[] = Object.values(InvoiceStatus).map(
		(val) => ({
			label: invoiceStatusMessage(intl, val),
			value: val,
		}),
	)

	const invoices = useInvoices(selectedFinanceCompanyUUID, filters)
	const dispatch = useDispatch()

	const [createSingleDialog, setCreateSingleDialog] = useState(false)
	const [createDialog, setCreateDialog] = useState(false)
	const [massInvoicing, setMassInvoicing] = useState(false)

	const showMassDialog = () => {
		setCreateDialog(true)
		setMassInvoicing(true)
	}

	const hideDialog = () => {
		setCreateDialog(false)
		setCreateSingleDialog(false)
		setMassInvoicing(false)
	}

	const renderTable = () => {
		if (invoices.loading) {
			return (
				<LoadingContainer>
					<LoadingState />
				</LoadingContainer>
			)
		}

		if (!invoices.data || !invoices.data.length) {
			return (
				<p>
					{intl.formatMessage({
						defaultMessage: 'Laskuja ei löytynyt.',
						description: 'Message for empty finance invoices list.',
					})}
				</p>
			)
		}

		return (
			<InvoicesTable
				invoices={invoices.data}
				refreshInvoices={() => invoices.refresh()}
				apartmentOptions={apartmentOptions}
			/>
		)
	}

	const buttons = [
		{
			key: 'mass_invoice',
			secondary: true,
			iconName: 'add',
			label: intl.formatMessage({
				defaultMessage: 'Luo massalaskut',
				description: 'Label for the create a finance invoice button.',
			}),
			action: () => showMassDialog(),
		},
		{
			key: 'invoice',
			secondary: true,
			iconName: 'add',
			label: intl.formatMessage({
				defaultMessage: 'Luo lasku',
				description: 'Label for the create a finance invoice button.',
			}),
			action: () => setCreateDialog(true),
		},
		{
			key: 'single_invoice',
			secondary: true,
			iconName: 'add',
			label: intl.formatMessage({
				defaultMessage: 'Luo yksittäislasku',
				description: 'Label for the create a finance invoice button.',
			}),
			action: () => setCreateSingleDialog(true),
		},
	]

	return (
		<Container>
			<Filters
				filters={filters}
				setFilters={setFilters}
				apartmentOptions={apartmentOptions}
				invoicingOptions={invoicingOptions}
				statusOptions={statusOptions}
			/>

			{renderTable()}

			<FloatingActionBtnMenu
				iconName="add"
				text={intl.formatMessage({
					defaultMessage: 'Luo laskuja',
					description: 'Label for the create a finance invoice button.',
				})}
				buttons={buttons}
			/>

			{createDialog && (
				<InvoiceCreateDialog
					companyUUID={selectedFinanceCompanyUUID}
					onHide={() => hideDialog()}
					refreshInvoices={() => invoices.refresh()}
					massInvoicing={massInvoicing}
					apartmentOptions={apartmentOptions}
					settings={settings}
					paymentProducts={paymentProducts}
					vatOptions={vatOptions}
				/>
			)}

			{createSingleDialog && (
				<InvoiceCreateDialog
					companyUUID={selectedFinanceCompanyUUID}
					onHide={() => hideDialog()}
					refreshInvoices={() => invoices.refresh()}
					massInvoicing={false}
					apartmentOptions={apartmentOptions}
					settings={settings}
					singleInvoice={true}
					paymentProducts={paymentProducts}
					vatOptions={vatOptions}
				/>
			)}

			{queryParamUUID && (
				<InvoiceDialog
					invoiceUUID={queryParamUUID}
					onHide={() => {
						let url = '/manager/finance/invoices' + HASH_INVOICES
						if (consolidatedUUID) {
							url =
								'/manager/finance/invoices' +
								HASH_CONSOLIDATED_INVOICES +
								'?uuid=' +
								consolidatedUUID
						}
						dispatch(push(url))
					}}
					refreshInvoices={() => invoices.refresh()}
					apartmentOptions={apartmentOptions}
					back={consolidatedUUID ? true : false}
					paymentProducts={paymentProducts}
					vatOptions={vatOptions}
				/>
			)}
		</Container>
	)
}

export default InvoiceView
