import { useState, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import Button from 'react-md/lib/Buttons'
import FontIcon from 'react-md/lib/FontIcons'
import styled from 'styled-components'
import PaymentProductCreateDialog from 'ui/ManagerFinance/PaymentProducts/PaymentProductCreateDialog'
import { LoanCreateFormInputs } from '../types'
import PaymentProductsTable from 'ui/ManagerFinance/PaymentProducts/PaymentProductsTable'
import useValueAddedTaxes from 'ui/ManagerFinance/ValueAddedTax/useValueAddedTaxes'
import { createVatOptions } from 'ui/ManagerFinance/utils/vatOptions'
import usePaymentProducts from 'ui/ManagerFinance/PaymentProducts/usePaymentProducts'
import PaymentProductEditDialog from 'ui/ManagerFinance/PaymentProducts/PaymentProductEditDialog'

const AddProductPaymentButton = styled(Button)`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	margin-top: 0.5rem;
	gap: 0.5rem;
`

const CreatePaymentProductContainer = styled.div`
	padding: 0 1.5rem;
`

function LoanCreatePaymentProduct() {
	const [createPaymentProductDialogOpen, setCreatePaymentProductDialogOpen] =
		useState(false)
	const [editingPaymentProduct, setEditingPaymentProduct] = useState<
		string | null
	>(null)
	const { watch } = useFormContext<LoanCreateFormInputs>()
	const [companyUUID, loanUUID] = watch(['companyUUID', 'uuid'])
	const { valueAddedTaxes } = useValueAddedTaxes()
	const vatOptions = createVatOptions(valueAddedTaxes || [])

	const paymentProductFilters = useMemo(
		() => ({
			loanUUID,
			separateBilling: null,
			vatUUID: null,
			date: new Date(),
			searchText: null,
		}),
		[loanUUID],
	)
	const paymentProducts = usePaymentProducts(companyUUID, paymentProductFilters)

	const onEditPaymentProduct = (uuid: string) => {
		setEditingPaymentProduct(uuid)
	}

	return (
		<CreatePaymentProductContainer>
			<p style={{ marginBottom: '2rem' }}>
				<FormattedMessage
					defaultMessage="Jotta voimme yhdistää vastikelaskuja lainalle, on ensin luotava maksulaji lainan vastikkeelle. Sen jälkeen voit lisätä luomasi maksulajin osakkaiden sopimuksille."
					description="In order to link service charge invoices to a loan, you must first create a payment type for the loan's service charge. After that, you can add the payment type you created to the shareholders' agreements."
				/>
			</p>
			{loanUUID && paymentProducts.data.length > 0 && (
				<PaymentProductsTable
					paymentProducts={paymentProducts.data}
					vatOptions={vatOptions}
					refresh={paymentProducts.refresh}
					onEditPaymentProduct={onEditPaymentProduct}
				/>
			)}
			{!loanUUID ||
				(paymentProducts.data.length === 0 && (
					<div>
						<AddProductPaymentButton
							secondary
							flat
							onClick={() => setCreatePaymentProductDialogOpen(true)}
						>
							<FontIcon
								style={{
									color: 'var(--color-secondary-dark)',
								}}
							>
								add
							</FontIcon>
							<FormattedMessage
								defaultMessage="Lisää uusi maksulaji"
								description="Button label for adding new date"
							/>
						</AddProductPaymentButton>
					</div>
				))}
			{createPaymentProductDialogOpen && (
				<PaymentProductCreateDialog
					companyUUID={companyUUID}
					loanUUID={loanUUID}
					onHide={() => setCreatePaymentProductDialogOpen(false)}
					refreshPaymentProducts={paymentProducts.refresh}
					paymentProducts={[]}
					vatOptions={[]}
				/>
			)}
			{editingPaymentProduct && (
				<PaymentProductEditDialog
					paymentProductUUID={editingPaymentProduct}
					onHide={() => setEditingPaymentProduct(null)}
					refreshPaymentProducts={paymentProducts.refresh}
					paymentProducts={[]}
					vatOptions={[]}
				/>
			)}
		</CreatePaymentProductContainer>
	)
}

export default LoanCreatePaymentProduct
